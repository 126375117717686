<template>
  <div>
    <div class="Formativity">
      <v-card elevation="0" class="Formativity-Cards">
        <h2 class="Tramite_styles">
          Trámites
          <strong
            class="d-flex"
            style="position: absolute; right: 10px; top: 10px"
          >
            {{ totalTurns }}
            <v-icon
              class="d-flex align-center justify-center text-18 mdi-account"
              >mdi-account</v-icon
            >
          </strong>
          <div
            style="color: red"
            class="d-flex flex-column justify-center align-center"
            v-if="showFromAction"
          >
            <small color="danger">
              {{ errorPolling }}
            </small>
          </div>
        </h2>
        <VuePerfectScrollbar class="scroll-area" :settings="settings">
          <div class="Menus">
            <div class="d-flex">
              <div style="width: 100%">
                <template v-if="queueGroups">
                  <div
                    :id="queueGroup.id"
                    class="Menus-Item Menu_tramite text-18 pl-6 primary--text v-list-item v-list-item--link theme--light"
                    v-for="(queueGroup, index) in queueGroupsSort"
                    :key="index"
                    style="justify-content: space-between"
                    @click="(e) => changeDefaultWaitingRoomGroup(e, queueGroup)"
                  >
                    <span class="name">{{ queueGroup.name }}</span>
                    <span
                      class="d-flex icon-dk"
                      style="
                        justify-content: space-between;
                        position: absolute;
                        right: 10px;
                      "
                    >
                      {{ queueGroup.queue.standBy }}
                      <v-icon
                        class="d-flex align-center justify-center text-18 mdi-account"
                        >mdi-account</v-icon
                      >
                    </span>
                  </div>
                </template>
                <template v-else>
                  <p>No hay trámites</p>
                </template>
              </div>
            </div>
          </div>
        </VuePerfectScrollbar>
      </v-card>
      <v-card elevation="0" class="Formativity-Cards">
        <h2 class="d-flex">Alerta de {{ selectedName }}</h2>
        <VuePerfectScrollbar class="scroll-area" :settings="settings">
          <div class="Menus">
            <div
              class="Menus-Item text-18 pl-6 primary--text v-list-item v-list-item--link theme--light"
              style="cursor: initial !important"
            >
              <span style="max-width: 180px; min-width: 180px"
                >Cliente en espera</span
              >
              <div class="green window">
                <v-icon>mdi-check-bold</v-icon>
              </div>
            </div>
            <div
              class="Menus-Item text-18 pl-6 primary--text v-list-item v-list-item--link theme--light"
              style="cursor: initial !important"
            >
              <span style="max-width: 180px; min-width: 180px">
                Máximo tiempo de espera
              </span>
              <div class="window" :class="warningColor">
                <v-icon>mdi-check-bold</v-icon>
              </div>
            </div>
            <div
              class="Menus-Item text-18 pl-6 primary--text v-list-item v-list-item--link theme--light"
              style="cursor: initial !important"
            >
              <span style="max-width: 180px; min-width: 180px">
                Máximo tiempo total
              </span>
              <div class="window green">
                <v-icon>mdi-check-bold</v-icon>
              </div>
            </div>
          </div>
        </VuePerfectScrollbar>
      </v-card>
      <v-card
        elevation="0"
        class="Formativity-Cards"
        v-if="viewTurns && queueStandBy.length && selectedGroup.length"
      >
        <h2 class="titleInformation">Información del turno</h2>
        <VuePerfectScrollbar
          class="scroll-area-turn"
          :settings="settings"
          style="max-height: 200px"
        >
          <div class="Menus">
            <div
              :id="'turn_' + turn.id || index"
              class="Menus-Item text-18 pl-6 primary--text v-list-item v-list-item--link theme--light d-flex"
              style="justify-content: space-between; cursor: pointer"
              v-for="(turn, index) in selectedGroup"
              :key="index"
              @click="() => changeSelectedTurn(turn, index)"
            >
              <div style="display: flex; align-items: center">
                <p style="margin: 0">{{ turn.letter + turn.number }}</p>
                <div class="label" v-if="turn.lastAction === 'ReQueue'">F</div>
                <div
                  class="label label-green"
                  v-else-if="turn.lastAction === 'Derivate'"
                >
                  D
                </div>
                <div
                  class="label label-red"
                  v-else-if="turn.lastAction === 'Cancelled'"
                >
                  C
                </div>
              </div>
              <p style="justify-self: flex-end; margin: 0">
                {{ turn.turnConverted }}
              </p>
            </div>
          </div>
        </VuePerfectScrollbar>
      </v-card>
      <v-card
        elevation="0"
        class="Formativity-Cards"
        v-if="selectedTurn && queueStandBy.length"
      >
        <VuePerfectScrollbar
          class="scroll-area-turn"
          :settings="settings"
          style="max-height: 350px"
        >
          <h2 class="titleInformation">
            Datos extra del turno
            {{ selectedTurn.letter + selectedTurn.number }}
          </h2>
          <div class="Menus">
            <div
              class="Menus-Item text-18 pl-6 primary--text v-list-item v-list-item--link theme--light d-block"
              style="justify-content: space-between; cursor: initial !important"
            >
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <p style="margin: 0">Tiempo total de atención:</p>
                <span style="margin: 0">00:00:00</span>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <p style="margin: 0">Tiempo total de espera:</p>
                <span style="margin: 0">{{ selectedTurn.turnConverted }}</span>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <p style="margin: 0">Tiempo total:</p>
                <span style="margin: 0">{{
                  selectedTurn.totalAttentionTime || selectedTurn.turnConverted
                }}</span>
              </div>
              <div v-if="customerData">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <p style="margin: 0">Nombre:</p>
                  <span style="margin: 0">{{ customerData.firstName }}</span>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <p style="margin: 0">DNI:</p>
                  <span style="margin: 0">{{ customerData.dni }}</span>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                  v-if="customerData.cuit"
                >
                  <p style="margin: 0">CUIT:</p>
                  <span style="margin: 0">{{ customerData.cuit }}</span>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                  v-if="customerData.cedulaIdentidad"
                >
                  <p style="margin: 0">Cédula Identidad:</p>
                  <span style="margin: 0">{{
                    customerData.cedulaIdentidad
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </VuePerfectScrollbar>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  name: "Formativity",
  data() {
    return {
      settings: {
        maxScrollbarLength: 30,
      },
      selectedQueueObject: {},
      selectedQueueObjectGroup: {},
      counterWarningMinute: 0,
      counterWarningSecond: 0,
      counterWarning: null,
      stopCounter: null,
      intervalTurns: null,
      now: moment(),
      // temporaly count groups
      turns: 0,
      selectedTurn: null,
      viewTurns: false,
      timerToCall: null,
      $queue: null,
      $queueTurn: null,
    };
  },
  components: {
    VuePerfectScrollbar: () => import("vue-perfect-scrollbar"),
  },
  props: ["queueGroups", "selectOptionState"],
  created() {
    this.intervalTurns = setInterval(() => {
      this.now = moment();
    }, 1000);
    this.getAllData();
    this.restartCounter();
  },
  mounted() {
    this.getAllData();
  },
  computed: {
    ...mapState({
      derivableQueueGroups: (state) =>
        state.loggedUserSilver.derivableQueueGroups,
      selectedQueueState: (state) => state.loggedUserSilver.selectedQueueState,
      pollingData: (state) => state.pollingWorkplaceSilver.polling,
      finishCall: (state) => state.loggedUserSilver.finishCall,
      callData: (state) =>
        state.loggedUserSilver.loggedUserData.worker?.actualTurn,
      selectedQueueGroup: (state) =>
        state.pollingWorkplaceSilver.polling?.selectedQueueGroup,
      config: (state) => state.loggedUserSilver.loggedUserData.worker?.configs,
      selectedBranch: (state) => state.branchesSilver.selectedBranch,
      videoCallSilverState: (state) => state.videoCallSilver.videoData,
      errorPolling: (state) => state.pollingWorkplaceSilver.errorPolling,
      showFromAction: (state) => state.pollingWorkplaceSilver.showFromAction,
    }),
    customerData() {
      return this.selectedTurn.customer;
    },
    selectedQueue() {
      return this.selectedQueueObjectGroup?.name ||
        this.derivableQueueGroups?.length
        ? this.derivableQueueGroups[0].name
        : "";
    },
    selectedGroup() {
      this.now;
      return this.selectedQueueGroup?.turns.map((turn) => {
        if (this.selectedTurn)
          this.selectedTurn.turnConverted = this.timeStampInitHandler(
            turn.initTimeStamp
          );
        turn.turnConverted = this.timeStampInitHandler(turn.initTimeStamp);
        return turn;
      });
    },
    pollingGroup() {
      return this.pollingData?.callableQueueGroups || [];
    },
    // turnGroup() {
    //   const selectedQueue = this.changeDefaultWaitingRoom;
    //   this.queueGroups?.filter((queueGroup) => {
    //     if (queueGroup.queue.turns.length !== 0) {
    //       selectedQueue(queueGroup);
    //       return this.selectedQueueState;
    //     }
    //   });
    //   return this.selectedQueueState?.turns || [];
    // },
    queueGroupsSort() {
      const queueGroups = this.queueGroups;
      return queueGroups.sort((a, b) => {
        if (a.queue.standBy > b.queue.standBy) {
          return -1;
        }
        if (a.queue.standBy < b.queue.standBy) {
          return 1;
        }
        return 0;
      });
    },
    selectedName() {
      return this.queueGroups?.length ? this.queueGroups[0].name : "";
    },
    timerInitTurn() {
      return this.initTimeStamp;
    },
    totalTurns() {
      return (
        this.queueGroups?.reduce(
          (accum, item) => accum + +item.queue.standBy,
          0
        ) || 0
      );
    },
    queueStandBy() {
      return this.queueGroups?.filter((item) => item.queue.standBy) || [];
    },
    warningColor() {
      let selectOptionState = this.selectOptionState;
      return this.counterWarningMinute >= 5
        ? "yellow"
        : this.counterWarningMinute >= 10 && this.counterWarningMinute < 11
        ? "red"
        : this.counterWarningMinute >= 11
        ? (() => {
            selectOptionState = "Ausente";
            console.log(selectOptionState);
            // this.stopCounter = true;
            // clearInterval(this.counterWarning);
            // this.cleanCounter();
            return "red";
          })()
        : "green";
    },
  },
  watch: {
    finishCall(val) {
      if (val) {
        this.selectedQueueObject = {};
        this.selectedQueueObjectGroup = {};
      }
    },
    queueStandBy(val) {
      if (val && !this.counterWarning && !this.stopCounter) {
        this.restartCounter();
      }
      if (!val && this.counterWarning) {
        clearInterval(this.counterWarning);
        this.cleanCounter();
      }
    },
    totalTurn(val) {
      if (!val.length) this.viewTurns = false;
    },
    // await this.handlerDerivableQueueGroups();
    "$route.path"(val) {
      if (val) {
        console.log(val);
        this.timerToCall = setInterval(async () => {
          await this.handlerDerivableQueueGroups();
          clearInterval(this.timerToCall);
        }, 5200);
      }
    },
    // selectedQueueState(val) {
    //   if (val && val.standBy) {
    //     this.addFirstTurnAction(val.turns[0]);
    //   }
    // },
    async selectedQueueGroup() {
      if (
        this.selectedQueueGroup?.turns &&
        this.config &&
        this.config.turnNotification
      ) {
        if (this.turns > 0) {
          let permission = await this.requestPermissionNotification();
          if (this.turns < this.selectedQueueGroup.turns.length) {
            if (permission) {
              let turn =
                this.selectedQueueGroup.turns[
                  this.selectedQueueGroup.turns.length - 1
                ];
              turn = turn.letter + turn.number;
              this.newStandByTurn(turn);
              this.turns = this.selectedQueueGroup.standBy;
            }
          }
        } else {
          this.turns = this.selectedQueueGroup.standBy;
          if (this.turns > 1) {
            let permission = await this.requestPermissionNotification();
            if (permission) {
              this.stanbyTurns(this.turns);
            }
          }
        }
      }
    },
  },
  destroyed() {
    clearInterval(this.intervalTurns);
    clearInterval(this.counterWarning);
    clearInterval(this.timerToCall);
    this.cleanCounter();
  },
  methods: {
    ...mapActions([
      "handlerDerivableQueueGroups",
      "handlerSelectedQueue",
      "requestPermissionNotification",
      "newStandByTurn",
      "stanbyTurns",
      "addFirstTurnAction",
    ]),
    async getAllData() {
      await this.handlerDerivableQueueGroups();
      this.changeDefaultWaitingRoomGroup(null, this.queueGroups?.[0]);
    },
    changeDefaultWaitingRoom(object) {
      this.selectedQueueObject = object;
      this.handlerSelectedQueue(object.id);
    },
    cleanCounter() {
      this.counterWarning = null;
      this.counterWarningMinute = 0;
      this.counterWarningSecond = 0;
    },
    timeStampInitHandler(timeStamp) {
      const today = moment();
      const newDate = moment(timeStamp);
      const hours = today.diff(newDate, "hours");
      const minutes = today.diff(newDate, "minutes");
      const seconds = today.diff(newDate, "seconds"); //diferencia en segundos

      const secondsRemaining = seconds - minutes * 60;
      const minutesRemaining = minutes - hours * 60;
      return `
        ${hours > 9 ? hours : "0" + hours}:${
        minutesRemaining > 9 ? minutesRemaining : "0" + minutesRemaining
      }:${secondsRemaining > 9 ? secondsRemaining : "0" + secondsRemaining}`;
    },
    async changeDefaultWaitingRoomGroup(e, object) {
      this.selectedTurn = null;
      if (e && this.selectedGroup?.length) this.viewTurns = true;
      if (e) this.activeButton(object);
      this.$queueTurn = null;
      if (object) {
        this.selectedQueueObjectGroup = object;
        await this.handlerSelectedQueue(object.id || object.queue?.id);
      }
    },
    activeButton(object) {
      if (this.$queue && Number(this.$queue.id) !== object.id) {
        this.$queue.classList.remove("v-list-item-active-dk");
        this.$queue.classList.add("v-list-item");
        this.$queue = document.getElementById(object.id);
        this.$queue.classList.add("v-list-item-active-dk");
        this.$queue.classList.remove("v-list-item");
      } else {
        this.$queue = document.getElementById(object.id);
        this.$queue.classList.remove("v-list-item");
        this.$queue.classList.add("v-list-item-active-dk");
      }
    },
    activeButtonTurns(object) {
      if (this.$queueTurn && Number(this.$queueTurn.id) !== object.id) {
        this.$queueTurn.classList.remove("v-list-item-active-dk");
        this.$queueTurn.classList.add("v-list-item");
        this.$queueTurn = document.getElementById("turn_" + object.id);
        this.$queueTurn.classList.add("v-list-item-active-dk");
        this.$queueTurn.classList.remove("v-list-item");
      } else {
        this.$queueTurn = document.getElementById("turn_" + object.id);
        this.$queueTurn.classList.remove("v-list-item");
        this.$queueTurn.classList.add("v-list-item-active-dk");
      }
    },
    changeSelectedTurn(turn) {
      this.selectedTurn = turn;
      this.activeButtonTurns(turn);
    },
    restartCounter() {
      clearInterval(this.counterWarning);
      this.cleanCounter();
      if (this.queueStandBy?.length)
        this.counterWarning = setInterval(() => {
          this.counterWarningSecond += 1;
          if (this.counterWarningSecond === 60) {
            this.counterWarningSecond = 0;
            this.counterWarningMinute += 1;
          }
        }, 1000);
    },
  },
};
</script>

<style scoped>
.Formativity {
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-start;
  flex-wrap: wrap;
}

.v-list-item-active-dk {
  color: rgb(56, 105, 241);
  background: rgba(194, 218, 240, 0.767);
  padding: 0.9rem !important;
  padding-left: 1.5rem !important;
  transition: 0.2s all;
}

.v-list-item-active-dk:hover {
  color: rgb(56, 105, 241);
  background: rgba(56, 105, 241, 0.096);
}

.Formativity h2 {
  /* border-bottom: 1px solid; */
  font-size: 1rem;
  padding: 0.5rem;
}

.Formativity-Cards {
  width: 100%;
  max-width: 27%;
  height: 250px;
  /* overflow: auto; */
  min-width: 300px;
  margin-bottom: 0;
}

.Formativity-Cards:nth-child(2) {
  /* border-right: 1px solid #ddd;
  border-left: 1px solid #ddd; */
  margin: 0.5rem;
  margin-top: 0;
  max-width: 20%;
  margin-bottom: 0;
}

.Formativity-Cards:nth-child(3) {
  /* border-right: 1px solid #ddd;
  border-left: 1px solid #ddd; */
  max-width: 20%;
}

.Formativity-Cards:nth-child(4) {
  /* border-right: 1px solid #ddd;
  border-left: 1px solid #ddd; */
  margin-left: 0.5rem;
  max-width: 25%;
}

.Menus-Item {
  padding: 0rem 1rem;
  font-size: 0.95rem !important;
  width: 100%;
}

.Menus-Item .name {
  max-width: 180px;
  min-width: 180px;
}

.Menus-Item .icon-dk {
  margin-left: 30%;
}

.Menus {
  min-height: 200px;
  height: 100%;
}

.titleInformation {
  display: flex;
  align-items: center;
}

.Menu_tramite {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.Tramite_styles {
  position: relative;
}

.window {
  width: 22px;
  height: 22px;
  padding: 0.8rem;
  border-radius: 50%;
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  opacity: 0.8;
  /* border: 1px solid black; */
}

.green {
  background-color: rgb(190, 250, 27);
}

.yellow {
  background-color: rgb(238, 216, 23);
}

/* .Turn__Formativity {
  display: inline-block;
} */

.red {
  background-color: red;
}

.label {
  color: rgb(91, 151, 204);
  border: 2px solid rgb(83, 144, 197);
  padding: 0 0.2rem;
  border-radius: 10%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: flex-start;
  height: 30px;
  margin-left: 0.5rem;
}

.label-green {
  color: rgb(65, 182, 110);
  border: 2px solid rgb(69, 179, 111);
}

.label-red {
  color: rgb(209, 86, 86);
  border: 2px solid rgb(189, 75, 75);
}

@media screen and (max-width: 1360px) {
  .Formativity {
    flex-wrap: wrap;
  }
  .Formativity-Cards {
    max-width: 30%;
    margin-left: 0;
    margin-right: 0;
  }
  .Panel_Video .Formativity-Cards {
    max-width: 100%;
    margin: 0.25rem 0;
    height: 100%;
  }
  .Panel_Video .Menus {
    max-height: 100%;
  }
  .Formativity-Cards:nth-child(4) {
    margin: 0;
    margin-top: 0.5rem;
  }
  /* .Formativity-Cards:nth-child(3),
  .Formativity-Cards:nth-child(4) {
    margin-top: 0.5rem;
  } */
  .Menus-Item .name {
    max-width: 120px;
    min-width: 120px;
  }
  .Menus-Item .icon-dk {
    margin-left: 20%;
  }
}

@media screen and (max-width: 1000px) {
  .Formativity {
    flex-wrap: wrap;
  }
  .Formativity-Cards:nth-child(2) {
    max-width: 100%;
    height: 100%;
    margin: 0;
  }
  .Formativity-Cards:nth-child(3) {
    max-width: 100%;
    height: 100%;
  }
  .Formativity-Cards:nth-child(4) {
    max-width: 100%;
    height: 100%;
  }
  .Formativity-Cards {
    max-width: 100%;
    height: 100%;
  }
  .Menus-Item .name {
    max-width: 130px;
    min-width: 130px;
  }
  .Menus {
    max-height: 100%;
  }
  .scroll-area,
  .scroll-area-turn {
    max-height: 100% !important;
  }
}

.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  max-height: 200px;
}

.scroll-area-turn {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  max-height: 250px;
}
</style>
